body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-rtc {
  background-color: #7CBA37;
}

.container--rtc {
  background-color: black;
  height: 100vh;
  margin: 0 !important;
  padding: 0 !important;
}

.btn.btn--rtc {
  color: #fff;
  background-color: #7CBA37;
  border-color: #7CBA37;
}

.btn.btn--rtc:hover {
  color: #fff;
}

.container-card-video {
  position: fixed;
  z-index: 99999;
  width: 100%;
}

.item-card-video {
  position: relative;
  /* width: 150px; */
  height: 100px;
  overflow: hidden;
  background-color: black;
  border: 1px solid gray;
  text-align: center;
}

.video-src {
  width: 100%;
  height: 100px;
  position: absolute;
  z-index: 200;
}

.video-cover {
    width: 100%;
    height: 100px;
    position: absolute;
    z-index: 100;
    text-align: center;    
}

.video-cover img {
  margin-top: 35px;
}

.item-card-video .video-src > video {
  height: 100%;
  /* max-width: auto; */
  width: 100%;
}

#video-full-src {
  z-index: 400;
  position: absolute;
}

.video-full-cover {
  background-color: transparent;
  z-index: 300;
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
  
}

#container-video-full #video-full-src > video {
  height: 100vh!important;
  width: 100vw!important;
}

.col-video-custom {
  margin-left: 5%;
}

/*
* classes for end room
*/

.endroom-action {
  text-align: center;
}

.st0{fill:none;}
.st1{fill:#FFFFFF;}

.loading-participant{
  background-color: black;
  bottom: 0;
  color: white;
  position: absolute;
  z-index: 5000;
  margin-left: 5%;
}


/* Para 320px */  
@media only screen and (max-width: 425px) and (min-width: 5px)  {  
  .row-min {
    display: list-item !important;
  }

  .item-card-video {
    /* position: relative; */
    width: 70px;
    height: 40px;
    margin-top: 5px;
    /* overflow: hidden; */
    /* background-color: black; */
    border: 1px solid gray;
    /* text-align: center; */
  }

  .video-src {
    height: 40px;
  }
  
  .video-cover {
      height: 40px;    
  }

  .video-cover img {
    margin-top: 8px;
  }

  .loading-participant{
    font-size: 12px;
  }

}